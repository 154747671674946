import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'

interface IProps {
  name: string
  type?: 'text' | 'number'
  value?: string | number
  defaultValue?: string
  readonly?: boolean
  label?: null | string
  placeholder?: null | string
  error?: null | string
  required?: boolean
  password?: boolean
  inputRef?: any
  postEdit?: boolean
  disabled?: boolean
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = props => {
  const initialValueFromProps = props.defaultValue ?? props.value
  const [localValue, setLocalValue] = React.useState<string | number>(initialValueFromProps ?? '')

  React.useEffect(() => {
    if (props.value !== null && props.value !== undefined) {
      setLocalValue(props.value)
    }
  }, [props.value])

  return (
    <InputTextWrapper className="InputText">
      {props.label && <InputLabel label={props.label} required={props.required} />}
      <div className="InputText_Main">
        {props.postEdit && <span className="InputText_PostUrl">{I18n.t('post.post_url')}</span>}
        <input
          id={props.name}
          type={props.password ? 'password' : props.type ?? 'text'}
          name={props.name}
          placeholder={props.placeholder}
          required={props.required ? true : false}
          // defaultValue={props.defaultValue}
          value={localValue}
          onChange={event => {
            setLocalValue(event.target.value)
            if (typeof props.onChangeHandler === 'function') {
              props.onChangeHandler(event)
            }
          }}
          onBlur={event => {
            setLocalValue(event.target.value)
            if (typeof props.onBlurHandler === 'function') {
              props.onBlurHandler(event)
            }
          }}
          ref={props.inputRef || null}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
        />
        {props.error && <span className="InputText_Error">{props.error}</span>}
      </div>
    </InputTextWrapper>
  )
}

const InputTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border: solid 1px ${constants.COLORS.divider};
    border-radius: 4px;
    font-size: 14px;
    transition: border 0.2s ease;
    outline: none;
    background: none;

    &:not([readonly]):focus {
      border: solid 1px ${constants.COLORS.primary};
    }
  }

  input[readonly] {
    background-color: ${constants.COLORS.divider};
  }

  .InputText_Main {
    flex: 1;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
    }
  }

  .InputText_PostUrl {
    font-size: 13px;
    font-weight: bold;
  }

  .InputText_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.COLORS.cautionText};
    font-size: 14px;
  }
`

export default InputText
